import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import DatePicker from "react-multi-date-picker";
import * as api from "../components/API/staysApi";
import * as userapi from "../components/API/guestApi";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

function ManualBooking() {
  const toast = useToast();
  const [stay, setStay] = useState();
  const [users, setUsers] = useState();
  const [dates, setDates] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [blockDates, setBlockDates] = useState([]);
  const [checkOutBlocked, setChecoutBlocked] = useState();
  const [user, setUser] = useState("");
  const [adults, setAdults] = useState();
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [search, setSearch] = useState("");
  const { data: stays } = useQuery("Stays", api.getStaysForBooking);

  const { mutate: postMutate } = useMutation([], userapi.searchGuest, {
    onSuccess: (data) => {
      setUsers(data);
      toast({
        title: "Done",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });
  const { mutate: postBooking } = useMutation([], api.createBooking, {
    onSuccess: (data) => {
      if (data.isAvailable === false) {
        toast({
          title: "Booking  Failed",
          description: data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Booking Created Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });
  function handlePost(e) {
    e.preventDefault();
    postMutate({ searchWord: search });
  }

  function handleBlockedDates(stayData) {
    console.log(stayData);
    let dates = [];
    let cdates = [];
    let codates = [];
    setStay(stayData.property);
    stayData.blockedDates.map((bd, idx) => (dates[idx] = new Date(bd)));
    stayData.checkInDates.map((bd, idx) => (cdates[idx] = new Date(bd)));
    stayData.checkOutDates.map((bd, idx) => (codates[idx] = new Date(bd)));
    setBlockDates(dates.concat(cdates));
    setChecoutBlocked(dates.concat(codates));
  }

  function handleCreateBooking() {
    const start = dayjs(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
    const end = dayjs(endDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
    const body = {
      propertyId: stay?._id,
      checkInDate: start,
      checkOutDate: end,
      user: user?._id,
      numberOfGuests: {
        adults,
        childrens: children,
        infants,
      },
    };
    postBooking(body);
  }
  return (
    <Box ml={10} my={{ base: "20" }} w="75%">
      <Text fontSize={"24"} p={4} fontWeight={"bold"}>
        Create New Booking
      </Text>
      <Flex justifyContent={"space-between"} width="100%">
        <Box>
          <Text mb={"4"}>Select a Stay</Text>
          <Flex flexWrap={"wrap"}>
            {stays?.map((s) => (
              <Box
                cursor={"pointer"}
                border={"2px"}
                borderColor={stay?._id === s.property?._id ? "#FED438" : "black"}
                ml={"2"}
                mt={"2"}
                borderRadius={"md"}
                p={"2"}
                onClick={() => handleBlockedDates(s)}
              >
               
                <Text>{s.property?.name}</Text>
                {/* <Text>{s.property.city.name}</Text> */}
              </Box>
            ))}
          </Flex>
          <form onSubmit={handlePost}>
            <Flex mt={"5"}>
              <InputGroup>
                <InputLeftAddon children="Search for user" />
                <Input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
              <Button mx={"5"} type="submit">
                Search
              </Button>
            </Flex>
            {users && (
              <Select
                mt={"5"}
                textAlign={"center"}
                w={"50%"}
                onChange={(e) =>
                  setUser(users.find((s) => s._id === e.target.value))
                }
              >
                <option>---- Select ----</option>
                {users?.map((s) => (
                  <option key={s._id} value={s._id}>
                    {s.fullName.first + " " + s.fullName.last}
                  </option>
                ))}
              </Select>
            )}
          </form>
          <Text mt={"5"} fontSize="18px" fontWeight={"semibold"}>
            Select Date:
          </Text>
          {stay && (
            <HStack spacing={"10"}>
              <Box border={"1px"} borderRadius={"md"} cursor="pointer">
                <ReactDatePicker
                  className="datepicker"
                  placeholderText={
                    startDate
                      ? dayjs(startDate).format("DD-MM-YYYY")
                      : "Check In"
                  }
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={dayjs().toDate()}
                  excludeDates={blockDates}
                  dateFormat="dd-MM-yyyy"
                />
              </Box>

              <Box border={"1px"} borderRadius={"md"} cursor="pointer">
                <ReactDatePicker
                  className="datepicker"
                  placeholderText={
                    endDate ? dayjs(endDate).format("DD-MM-YYYY") : "Check Out"
                  }
                  onChange={(date) => setEndDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate ? startDate : dayjs().toDate()}
                  excludeDates={checkOutBlocked}
                />
              </Box>
            </HStack>
          )}
          <Text mt={"5"} fontSize="18px" fontWeight={"semibold"}>
            Number of Guests:
          </Text>
          <HStack mt={"2"} spacing={"5"} w={"70%"}>
            <InputGroup>
              <InputLeftAddon children="Adults" />
              <Input
                type="number"
                value={adults}
                onChange={(e) => setAdults(Number(e.target.value))}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children="Children" />
              <Input
                type="number"
                value={children}
                onChange={(e) => setChildren(Number(e.target.value))}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children="Infants" />
              <Input
                type="number"
                value={infants}
                onChange={(e) => setInfants(Number(e.target.value))}
              />
            </InputGroup>
          </HStack>
          <Button
            colorScheme={"green"}
            mt={"10"}
            onClick={() => handleCreateBooking()}
          >
            {" "}
            Create Booking
          </Button>
        </Box>

        {/* Stay Details */}
        <Box>
          <Text fontSize={"24"} p={4} fontWeight={"bold"}>
            Select Stay For more details:
          </Text>

          {stay && (
            <Box border="2px" borderRadius={"md"} py={"8"} px={"4"}>
              <Flex pb={"2"} borderBottom={"1px"} borderColor={"gray.300"}>
                <Text fontWeight={"semibold"} fontSize={"16px"}>
                  Name:
                </Text>
                <Text ml={"2"} fontWeight={"semibold"} fontSize={"16px"}>
                  {stay.name}
                </Text>
              </Flex>
              <Flex py={"2"} borderBottom={"1px"} borderColor={"gray.300"}>
                <Text fontWeight={"semibold"} fontSize={"16px"}>
                  City:
                </Text>
                <Text ml={"2"} fontWeight={"semibold"} fontSize={"16px"}>
                  {stay.city.name}
                </Text>
              </Flex>
              <Flex py={"2"} borderBottom={"1px"} borderColor={"gray.300"}>
                <Text fontWeight={"semibold"} fontSize={"16px"}>
                  Category:
                </Text>
                <Text ml={"2"} fontWeight={"semibold"} fontSize={"16px"}>
                  {stay.category.name}
                </Text>
              </Flex>
              <Flex py={"2"} borderBottom={"1px"} borderColor={"gray.300"}>
                <Text fontWeight={"semibold"} fontSize={"16px"}>
                  WeekDay Price:
                </Text>
                <Text ml={"2"} fontWeight={"semibold"} fontSize={"16px"}>
                  ${stay.priceAccordingToWeekDays.price}
                </Text>
              </Flex>
              <Flex py={"2"} borderBottom={"1px"} borderColor={"gray.300"}>
                <Text fontWeight={"semibold"} fontSize={"16px"}>
                  WeekEnd Price:
                </Text>
                <Text ml={"2"} fontWeight={"semibold"} fontSize={"16px"}>
                  ${stay.priceAccordingToWeekends.price}
                </Text>
              </Flex>
              <Flex py={"2"} borderBottom={"1px"} borderColor={"gray.300"}>
                <Text fontWeight={"semibold"} fontSize={"16px"}>
                  Cleaning Fees:
                </Text>
                <Text ml={"2"} fontWeight={"semibold"} fontSize={"16px"}>
                  ${stay.price.cleanFeas}
                </Text>
              </Flex>
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
}

export default ManualBooking;
