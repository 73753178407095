import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Input,
  Modal,
  Tabs,
  TabPanel,
  TabPanels,
  Tab,
  TabList,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  InputGroup,
  InputLeftAddon,
  FormControl,
  FormLabel,
  Switch,
  Image,
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useMutation, useQuery, useQueryClient } from "react-query";

import * as api from "../API/guestHouseApi";
import * as stayApi from "../API/staysApi";
import * as tourApi from "../API/toursApi";
import * as eventApi from "../API/eventsApi";
import * as highApi from "../API/highlightApi";
import * as amenApi from "../API/amenitiesApi";
import { useEffect, useRef, useState } from "react";

import ReactSelect from "react-select";
import { Editor } from "@tinymce/tinymce-react";

function AddGuestHouse({ Edit, Id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [id, setId] = useState();
  const [desc, setDesc] = useState();
  const [title, setTitle] = useState();
  const [barDesc, setBarDesc] = useState();
  const [barName, setBarName] = useState();

  const mainColor = useRef(null);
  const accentColor = useRef(null);

  const [barImage, setBarImage] = useState();
  const [barMenu, setBarMenu] = useState("");
  const editorRef = useRef(null);
  const [hasBar, setHasBar] = useState(false);
  const [isBookableBar, setIsBookableBar] = useState(false);
  const [slogan, setSlogan] = useState();

  const [cover, setCover] = useState();

  const [highlightsId, setHighlightsId] = useState([]);
  const [amenitiesId, setAmenitiesId] = useState([]);
  const [logo, setLogo] = useState();
  const [staysSelect, setStaysSelect] = useState([]);
  const [staysValue, setStaysValue] = useState([]);
  const [toursSelect, setToursSelect] = useState([]);
  const [toursValue, setToursValue] = useState([]);
  const [eventsSelect, setEventsSelect] = useState([]);
  const [eventsValue, setEventsValue] = useState([]);
  const [checkH, setCheckH] = useState();
  const [checkA, setCheckA] = useState();

  const queryClient = useQueryClient();
  const { data: events } = useQuery("Events", eventApi.getEvents);
  const { data: staysData } = useQuery("Stays", stayApi.getStays);
  const { data: toursData } = useQuery("Tours", tourApi.getTours);
  const { data: highlights } = useQuery("highlights", highApi.getHighlights);
  const { data: amenities } = useQuery("amenities", amenApi.getAmenities);

  // Add Guest House
  const { mutate: postMutate } = useMutation([], api.addGuestHouse, {
    onSuccess: () => {
      queryClient.invalidateQueries(["guesthouses"]);
      toast({
        title: "Added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  //   Get Stay Data for Edit
  const { data: guestHouse } = useQuery(
    ["GH", id],
    () => api.getGuestHouse(id).finally(() => {}),
    {
      enabled: Boolean(id),
    }
  );

  // UPDATE
  const { mutate: updateMutate } = useMutation([], api.updateGuestHouse, {
    onSuccess: () => {
      queryClient.invalidateQueries(["guesthouses"]);
      toast({
        title: "Added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const { mutate: updateStayMutate } = useMutation([], stayApi.updateStay, {
    onSuccess: () => {
      toast({
        title: "Stay Removed",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  // UPDATE Tour
  const { mutate: updateTourMutate } = useMutation([], tourApi.updateTour, {
    onSuccess: () => {
      toast({
        title: "Tour Removed",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    let staysD = [];
    let checkHigh = [];
    let checkAm = [];
    let eventsD = [];

    events?.map(
      (d, idx) =>
        (eventsD[idx] = {
          value: d._id,
          label: d.name,
          image: d.image?.length > 0 ? d.image : "",
        })
    );
    staysData?.map(
      (d, idx) =>
        (staysD[idx] = {
          value: d._id,
          label: d.name,
          image: d.images?.length > 0 ? d.images[0] : "",
        })
    );
    let toursD = [];
    toursData?.map(
      (d, idx) =>
        (toursD[idx] = {
          value: d._id,
          label: d.title,
          image: d.images?.length > 0 ? d.images[0] : "",
        })
    );
    setEventsSelect(eventsD);
    setToursSelect(toursD);
    setStaysSelect(staysD);
    if (Edit === true && guestHouse) {
      document.getElementById("maincolor").value =
        guestHouse.guestHouse.mainColor;
      document.getElementById("accentcolor").value =
        guestHouse.guestHouse.accentColor;
      highlights?.map(
        (h, idx) =>
          (checkHigh[idx] = guestHouse.guestHouse.highlights?.find(
            (amn) => amn._id === h._id
          )
            ? true
            : false)
      );
      amenities?.map(
        (h, idx) =>
          (checkAm[idx] = guestHouse.guestHouse.amenities?.find(
            (amn) => amn._id === h._id
          )
            ? true
            : false)
      );
      setBarMenu(guestHouse.guestHouse.barMenu);

      setAmenitiesId(guestHouse.guestHouse.amenities);
      setHighlightsId(guestHouse.guestHouse.highlights);
      setCheckH(checkHigh);
      setCheckA(checkAm);
      setTitle(guestHouse.guestHouse.name);
      setSlogan(guestHouse.guestHouse.slogan);
      setDesc(guestHouse.guestHouse.about);
      setHasBar(guestHouse.guestHouse.hasBar);
      setIsBookableBar(guestHouse.guestHouse.isBookableBar);
      setBarDesc(guestHouse.guestHouse.barDesc);
      setBarName(guestHouse.guestHouse.barName);
      setHighlightsId(guestHouse.guestHouse.highlights);
      let ss = [];
      guestHouse.stays.map(
        (s, i) => (ss[i] = { value: s._id, label: s.name, image: s.image })
      );

      let ts = [];
      guestHouse.tours.map(
        (t, i) => (ts[i] = { value: t._id, label: t.title, image: t.image })
      );

      let es = [];
      guestHouse?.guestHouse?.events?.map(
        (s, i) => (es[i] = { value: s?._id, label: s?.name, image: s?.image })
      );
      setEventsValue(es);
      setToursValue(ts);
      setStaysValue(ss);
    }
  }, [Edit, guestHouse, staysData, toursData, highlights, amenities, events]);

  function handleHighlights(id) {
    if (highlightsId?.find((hId) => hId._id === id._id)) {
      console.log("highlights-removed", highlightsId);
      setHighlightsId(highlightsId.filter((hId) => hId._id !== id._id));
    } else {
      if (highlightsId?.length < 1) {
        setHighlightsId([id]);
      } else {
        console.log("highlights-add", highlightsId);
        setHighlightsId([...highlightsId, id]);
      }
    }
  }

  function handleAmenities(id) {
    if (amenitiesId?.find((hId) => hId._id === id._id)) {
      setAmenitiesId(amenitiesId.filter((hId) => hId._id !== id._id));
    } else {
      if (amenitiesId?.length < 1) {
        setAmenitiesId([id]);
      } else {
        setAmenitiesId([...amenitiesId, id]);
      }
    }
  }

  function handleEdit() {
    onOpen();
    setId(Id);
  }

  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.value === object2.value;
      });
    });
  }

  async function handleChangeStays(value) {
    if (staysValue.length > value.length) {
      const stay = getDifference(staysValue, value);
      setStaysValue(value);
      const body = {};
      body.id = stay[0].value;
      body.guestHouse = null;
      updateStayMutate(body);
    } else {
      setStaysValue(value);
    }
  }

  async function handleChangeTours(value) {
    if (toursValue.length > value.length) {
      const tour = getDifference(toursValue, value);
      setToursValue(value);
      const body = {};
      body.id = tour[0].value;
      body.guestHouse = null;
      updateTourMutate(body);
    } else {
      setToursValue(value);
    }
  }

  // UPDATE REQUEST
  function handleUpdatingGuestHouse() {
    console.log(document.getElementById("maincolor"));
    const mColor = document.getElementById("maincolor").value;
    const aColor = document.getElementById("accentcolor").value;
    const data = new FormData();
    let etitles = [];
    let menu = editorRef.current.getContent();

    data.append("id", id);
    data.append("name", title);
    data.append("slogan", slogan);
    if (cover) data.append("cover", cover);
    if (logo) data.append("logo", logo);
    data.append("mainColor", mColor);
    data.append("accentColor", aColor);
    data.append("about", desc);
    data.append("hasBar", hasBar);
    data.append("isBookableBar", isBookableBar);
    data.append("barName", barName);
    data.append("barDesc", barDesc);
    if (barImage) data.append("barImg", barImage);
    data.append("barMenu", menu);

    let ev = [];
    eventsValue.map((s, idx) => {
      ev[idx] = s.value;
    });

    let sv = [];
    staysValue.map((s, idx) => {
      sv[idx] = s.value;
    });
    let tv = [];
    toursValue.map((t, idx) => {
      tv[idx] = t.value;
    });
    data.append("amenities", JSON.stringify(amenitiesId));
    data.append("highlights", JSON.stringify(highlightsId));
    data.append("eventTitles", JSON.stringify(etitles));
    data.append("events", JSON.stringify(ev));
    data.append("stays", JSON.stringify(sv));
    data.append("tours", JSON.stringify(tv));

    updateMutate(data);
  }

  // POST REQUEST
  function handleAddGuestHouse() {
    let etitles = [];
    let menu = editorRef.current.getContent();
    const mColor = document.getElementById("maincolor").value;
    const aColor = document.getElementById("accentcolor").value;
    const data = new FormData();

    data.append("name", title);
    data.append("slogan", slogan);
    if (cover) data.append("cover", cover);
    if (logo) data.append("logo", logo);
    data.append("mainColor", mColor);
    data.append("accentColor", aColor);
    data.append("about", desc);
    data.append("hasBar", hasBar);
    data.append("isBookableBar", isBookableBar);
    data.append("barName", barName);
    data.append("barDesc", barDesc);
    if (barImage) data.append("barImg", barImage);
    data.append("barMenu", menu);

    let sv = [];
    staysValue.map((s, idx) => {
      sv[idx] = s.value;
    });

    let ev = [];
    eventsValue.map((s, idx) => {
      ev[idx] = s.value;
    });

    let tv = [];
    toursValue.map((t, idx) => {
      tv[idx] = t.value;
    });

    data.append("amenities", JSON.stringify(amenitiesId));
    data.append("highlights", JSON.stringify(highlightsId));
    data.append("events", JSON.stringify(ev));
    data.append("stays", JSON.stringify(sv));
    data.append("tours", JSON.stringify(tv));

    postMutate(data);
  }

  return (
    <>
      {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={FiEdit}
          cursor={"pointer"}
          ml={4}
          size={6}
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={"#FED438"}>
          Add Guest House
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {Edit ? "Edit Guest House" : "Add Guest House"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>Info</Tab>
                <Tab>Details</Tab>
                <Tab>Bar Menu</Tab>
              </TabList>

              <TabPanels>
                {/* Details */}
                <TabPanel>
                  {/* Info */}
                  <HStack
                    alignItems={"start"}
                    justifyContent={"start"}
                    spacing={"15px"}
                  >
                    <Input
                      placeholder="Title"
                      // ref={title}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <InputGroup>
                      <InputLeftAddon
                        children={
                          guestHouse?.guestHouse?.logo ? (
                            <Image
                              w={10}
                              h={10}
                              src={guestHouse?.guestHouse?.logo}
                              alt="logo"
                            />
                          ) : (
                            "Logo"
                          )
                        }
                      />
                      <Input
                        type="file"
                        pt={1}
                        onChange={(e) => setLogo(e.target.files[0])}
                      />
                    </InputGroup>
                  </HStack>
                  <InputGroup my={5} w={"30%"}>
                    <InputLeftAddon
                      children={
                        guestHouse?.guestHouse?.cover ? (
                          <Image
                            w={10}
                            h={10}
                            src={guestHouse?.guestHouse?.cover}
                            alt="logo"
                          />
                        ) : (
                          "Cover"
                        )
                      }
                    />
                    <Input
                      onChange={(e) => setCover(e.target.files[0])}
                      type="file"
                      pt={1}
                    />
                  </InputGroup>
                  <Box mt={"5"}>
                    <Textarea
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                      placeholder="briefDescription"
                    />
                  </Box>
                  <Box mt={"5"}>
                    <Textarea
                      value={slogan}
                      onChange={(e) => setSlogan(e.target.value)}
                      placeholder="Slogan"
                    />
                  </Box>
                  {/* Colors */}
                  <Text my={5}>Colors</Text>
                  <HStack w={"50%"}>
                    <InputGroup>
                      <InputLeftAddon children="Main Color" />
                      <Input
                        id="maincolor"
                        type="color"
                        defaultValue={guestHouse?.guestHouse?.mainColor}
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputLeftAddon children="Accent Color" />
                      <Input
                        id="accentcolor"
                        type="color"
                        defaultValue={guestHouse?.guestHouse?.accentColor}
                      />
                    </InputGroup>
                  </HStack>
                  {/* Bar */}
                  <Text fontSize="18px" mt={10} mb={5}>
                    Bar
                  </Text>
                  <FormControl
                    as={Box}
                    alignItems={"center"}
                    columns={{ base: 2, lg: 4 }}
                  >
                    <HStack spacing={5}>
                      <FormLabel htmlFor="hasbar">Has Bar:</FormLabel>
                      <Switch
                        id="hasbar"
                        isChecked={hasBar}
                        onChange={(e) => setHasBar(e.target.checked)}
                      />
                    </HStack>
                    <HStack spacing={5} my={3}>
                      <FormLabel htmlFor="isBookable">is Bookable:</FormLabel>
                      <Switch
                        isChecked={isBookableBar}
                        onChange={(e) => setIsBookableBar(e.target.checked)}
                        id="isBookable"
                      />
                    </HStack>
                    <InputGroup w="25%" my="5">
                      <InputLeftAddon children="Bar Name" />
                      <Input
                        value={barName}
                        onChange={(e) => setBarName(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup w="50%" my="5">
                      <InputLeftAddon
                        children={
                          guestHouse?.guestHouse?.barImg ? (
                            <Image
                              src={guestHouse?.guestHouse?.barImg}
                              alt=""
                              w="10"
                              h="10"
                              rounded={"full"}
                            />
                          ) : (
                            "Bar Image"
                          )
                        }
                      />
                      <Input
                        type="file"
                        onChange={(e) => setBarImage(e.target.files[0])}
                      />
                    </InputGroup>
                    <Textarea
                      my="5"
                      value={barDesc}
                      onChange={(e) => setBarDesc(e.target.value)}
                      placeholder={"Bar Description"}
                      resize={"vertical"}
                    />
                  </FormControl>
                </TabPanel>
                {/* Special Events */}
                <TabPanel>
                  {/* Stays */}
                  <Text className="mt-10 mb-3">Special Events</Text>
                  <Box w="30%" my="my-5">
                    {staysSelect && (
                      <ReactSelect
                        options={eventsSelect}
                        value={eventsValue}
                        isMulti
                        placeholder="Select stays..."
                        onChange={setEventsValue}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        formatOptionLabel={(stay) => (
                          <HStack key={stay._id} spacing={5} className="">
                            <Image
                              src={stay.image ? stay.image : ""}
                              w={"14"}
                              h={"14"}
                              rounded={"full"}
                              alt=""
                            />
                            <span>{stay.label}</span>
                          </HStack>
                        )}
                      />
                    )}
                  </Box>

                  {/* Highlights */}
                  <Box>
                    <Text my={5}>Highlights:</Text>
                    <Flex flexWrap={"wrap"}>
                      {Edit
                        ? checkH &&
                          highlights?.map((h, idx) => {
                            return (
                              <Checkbox
                                my={"2"}
                                defaultChecked={checkH[idx]}
                                onChange={() => handleHighlights(h)}
                                px={2}
                                key={h._id}
                                colorScheme={"green"}
                              >
                                {h.name}
                              </Checkbox>
                            );
                          })
                        : highlights?.map((h, idx) => {
                            return (
                              <Checkbox
                                my={"2"}
                                onChange={() => handleHighlights(h)}
                                px={2}
                                key={h._id}
                                colorScheme={"green"}
                              >
                                {h.name}
                              </Checkbox>
                            );
                          })}
                    </Flex>
                  </Box>

                  {/* Amenities */}
                  <Box>
                    <Text my={5}>Amenities:</Text>
                    <Flex flexWrap={"wrap"}>
                      {Edit
                        ? checkA &&
                          amenities?.map((h, idx) => {
                            return (
                              <Checkbox
                                my={"2"}
                                defaultChecked={checkA[idx]}
                                onChange={() => handleAmenities(h)}
                                px={2}
                                key={h._id}
                                colorScheme={"green"}
                              >
                                {h.name}
                              </Checkbox>
                            );
                          })
                        : amenities?.map((h, idx) => {
                            return (
                              <Checkbox
                                my={"2"}
                                onChange={() => handleAmenities(h)}
                                px={2}
                                key={h._id}
                                colorScheme={"green"}
                              >
                                {h.name}
                              </Checkbox>
                            );
                          })}
                    </Flex>
                  </Box>

                  {/* Stays */}
                  <Text className="mt-10 mb-3">Stays</Text>
                  <Box w="30%" my="my-5">
                    {staysSelect && (
                      <ReactSelect
                        options={staysSelect}
                        value={staysValue}
                        isMulti
                        placeholder="Select stays..."
                        onChange={(value) => handleChangeStays(value)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        formatOptionLabel={(stay) => (
                          <HStack key={stay._id} spacing={5} className="">
                            <Image
                              src={stay.image ? stay.image : ""}
                              w={"14"}
                              h={"14"}
                              rounded={"full"}
                              alt=""
                            />
                            <span>{stay.label}</span>
                          </HStack>
                        )}
                      />
                    )}
                  </Box>
                  {/* Tours */}
                  <Text className="my-10 mb-3">Tours</Text>
                  <Box w="30%" my="my-5">
                    {toursSelect && (
                      <ReactSelect
                        options={toursSelect}
                        value={toursValue}
                        isMulti
                        placeholder="Select tours..."
                        onChange={(value) => handleChangeTours(value)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        formatOptionLabel={(tour) => (
                          <HStack key={tour._id} spacing={5} className="">
                            <Image
                              src={tour.image ? tour.image : ""}
                              w={"14"}
                              h={"14"}
                              rounded={"full"}
                              alt=""
                            />
                            <span>{tour.label}</span>
                          </HStack>
                        )}
                      />
                    )}
                  </Box>
                </TabPanel>
                {/* Bar Menu */}
                <TabPanel>
                  <Text mt={"10"} mb={"5"} fontSize="18px" fontWeight="bold">
                    Bar Menu
                  </Text>
                  <Editor
                    apiKey={"ztvmz6lwnmoiq8bqlmnv2ju7oj4csno3k4vqhuodpl2q0ju6"}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={barMenu ? barMenu : ""}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {Edit ? (
              <Button
                variant="outline"
                onClick={() => handleUpdatingGuestHouse()}
                bg={"green"}
                textColor={"white"}
              >
                {Edit ? "Update" : "Add"}
                <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            ) : (
              <Button
                variant="outline"
                onClick={() => handleAddGuestHouse()}
                bg={"green"}
                textColor={"white"}
              >
                {Edit ? "Update" : "Add"}
                <Icon ml={2} as={PlusSquareIcon} />
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddGuestHouse;
