import { Icon, PlusSquareIcon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/stayDetails";
import { useState } from "react";

import { MdOutlineDashboardCustomize } from 'react-icons/md'
import { useEffect } from "react";
import { baseFrontURL } from "../config";

function AddStayDetails({ Edit, Id }) {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const [action, setAction] = useState("post")
  const queryClient = useQueryClient();
  const [textDetails, setTextDetails] = useState()
  const [locationUrl, setLocationUrl] = useState()
  const [buildingPhoto, setBuildingPhoto] = useState()
  const [wifiName, setWifiName] = useState()
  const [wifiPassword, setWifiPassword] = useState()
  const [instructions, setInstructions] = useState()
  const [videoUrl, setVideoUrl] = useState()
  const [photoUrl, setPhotoUrl] = useState()
  const [parkingDetails,setParkingDetails] = useState()
  const [parkingImage,setParkingImage] = useState()
  const [electricityDetails,setElectricityDetails] = useState()
  const [elecImage,setElecImage] = useState()
  const [checkOutInstructions,setCheckOutInstructions] = useState()
  const [houseRules,setHouseRules] = useState()
  const [emergencyInfo,setEmergencyInfo] = useState()
  const [garbage,setGarbage] = useState()
  const [generalInfo,setGeneralInfo] = useState()
  const [id, setId] = useState()
  const [groceryStores,setGroceryStores] = useState(1)
  const [restaurants,setRestaurants] = useState(1)
  const [nightlife,setNightlife] = useState(1)
  const [entertainment,setEntertainment] = useState(1)
  const [transportation,setTransportation] = useState(1)


  function handleEdit() {
    onOpen();
    setId(Id);
  }

  const { data: StayDetails } = useQuery(
    ['StayDetails', id],
    () => api.getStayDetails(id).finally(() => {

    }),
    {
      enabled: Boolean(id),
      cacheTime:0,
      retry:false,
    }
  )

  useEffect(() => {
    if (StayDetails?._id?.length > 0) {
      setAction("update")
      setTextDetails(StayDetails?.location?.textDetails)
      setLocationUrl(StayDetails?.location?.locationUrl)
      setBuildingPhoto(StayDetails.location?.buildingPhoto)
      setInstructions(StayDetails?.keys?.instruction)
      setPhotoUrl(StayDetails?.keys?.photoUrl)
      setVideoUrl(StayDetails?.keys?.videoUrl)
      setWifiName(StayDetails.wifi.name)
      setWifiPassword(StayDetails.wifi.password)
      setCheckOutInstructions(StayDetails.checkOutInstructions)
      setGarbage(StayDetails.garbage)
      setGeneralInfo(StayDetails.generalInformation)
      setEmergencyInfo(StayDetails.emergencyInfo)
      setElectricityDetails(StayDetails.electricity.details)
      setHouseRules(StayDetails.houseRules)
      setParkingDetails(StayDetails.parking.details)
      setGroceryStores(StayDetails?.grocertStores?.length > 0 ? StayDetails?.grocertStores?.length : 1)
      setEntertainment(StayDetails?.entertainment?.length > 0 ? StayDetails?.entertainment?.length : 1)
      setRestaurants(StayDetails?.resturants?.length > 0 ? StayDetails?.resturants?.length : 1)
      setTransportation(StayDetails?.transportation?.length > 0 ? StayDetails?.transportation?.length : 1)
      setNightlife(StayDetails?.nightSpots?.length > 0 ? StayDetails?.nightSpots?.length : 1)

    }
  }, [StayDetails, action])

  

  // DELETE
  const { mutate: deleteMutate } = useMutation([], api.deleteStayDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Stays"]);
      onClose()
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });

  // UPDATE
  const { mutate: updateMutate } = useMutation([], api.updateStayDetails, {
    onSuccess: () => {
      toast({
        title: "Added successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      queryClient.invalidateQueries(["Stays",id]);
      onClose()
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });


  //POST
  const { mutate: postMutate } = useMutation([], api.addStayDetails, {
    onSuccess:
      () => {
        toast({
          title: "Added successfully",
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        queryClient.invalidateQueries(["Stays"]);
        queryClient.invalidateQueries(["Stays"]);
        onClose()
      },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })

  function handleDelete() {
    const data = {
      propertyId: id,

    }
    deleteMutate(data)
  }


  function handleClose() {
    onClose()
 
  }

  async function handlePost() {
    let grocertStores = [{}] 
    let resturants = [{}] 
    let nightSpots = [{}]   
    let entertainments =  [{}]  
    let transportations = [{}]

    // Fill the lists 
    
    // Grocery Stores
    ;[...Array(groceryStores)].map((_, i) => {
      grocertStores[i] = {
        groceryDetails:document.getElementById(`groceryText${i}`)?.value,
        phone:document.getElementById(`groceryPhone${i}`)?.value,
        link:document.getElementById(`groceryLink${i}`)?.value
      }
    });
    

    // Restaurants
    ;[...Array(restaurants)].map((_, i) => {
      resturants[i] = {
        resturantDetails:document.getElementById(`restaurantsText${i}`)?.value,
        phone:document.getElementById(`restaurantsPhone${i}`)?.value,
        link:document.getElementById(`restaurantsLink${i}`)?.value
      }
    });
    // Nightlife
    ;[...Array(nightlife)].map((_, i) => {
      nightSpots[i] = {
        nightSpotDetails:document.getElementById(`nightlifeText${i}`)?.value,
        phone:document.getElementById(`nightlifePhone${i}`)?.value,
        link:document.getElementById(`nightlifeLink${i}`)?.value
      }
    });

    // Entertainment
    ;[...Array(entertainment)].map((_, i) => {
      entertainments[i] = {
        entertainmentDetails:document.getElementById(`entertainmentText${i}`)?.value,
        phone:document.getElementById(`entertainmentPhone${i}`)?.value,
        link:document.getElementById(`entertainmentLink${i}`)?.value
      }
    });

    // Transportation
    ;[...Array(transportation)].map((_, i) => {
      transportations[i] = {
        transportationDetails:document.getElementById(`transportationText${i}`)?.value,
        phone:document.getElementById(`transportationPhone${i}`)?.value,
        link:document.getElementById(`transportationLink${i}`)?.value
      }
    });



    const data = new FormData()
    data.append('propertyId', id)
    data.append('textDetails', textDetails)
    data.append('instruction', instructions)
    data.append('locationUrl', locationUrl)
    data.append('wifiName', wifiName)
    data.append('wifiPassword', wifiPassword)

    data.append('parkingDetails', parkingDetails)
    data.append('electricityDetails', electricityDetails)
    data.append('houseRules', houseRules)
    data.append('emergencyInfo', emergencyInfo)
    data.append('garbage', garbage)
    data.append('generalInformation', generalInfo)
    data.append('checkOutInstructions', checkOutInstructions)
    data.append('grocertStores', JSON.stringify(grocertStores))
    data.append('resturants',  JSON.stringify(resturants))
    data.append('nightSpots',  JSON.stringify(nightSpots))
    data.append('entertainment',  JSON.stringify(entertainments))
    data.append('transportation',  JSON.stringify(transportations))
    if(elecImage && elecImage.slice(0,5) !== 'https') {
      console.log("electImage","Test")
      data.append('elecImage',elecImage)
    }
    if(parkingImage && parkingImage.slice(0,5) !== 'https') data.append('parkingImage',parkingImage)
    if (videoUrl && videoUrl.slice(0,5) !== 'https') data.append('keyVideo', videoUrl)
    if (photoUrl && photoUrl.slice(0,5) !== 'https') {
      console.log("appended");
      data.append('keyImage', photoUrl)
    }
    if (buildingPhoto && buildingPhoto.slice(0,5) !== 'https') data.append('building', buildingPhoto)


    if (action === "post") postMutate(data)
    if (action === "update") updateMutate(data)
  }

  return (
    <>
      <Icon cursor={"pointer"} mx={2} onClick={() => handleEdit()} as={MdOutlineDashboardCustomize} ml={4} size={7} fontSize={"bold"} />
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{'Add Stay Details'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Link href={`${baseFrontURL}/staymoredetails/${id}`} target="_blank"><Button colorScheme="blue">View</Button></Link>
            <Tabs>
              <TabList>
                <Tab>Info</Tab>
                <Tab>Details</Tab>
                <Tab>Rules</Tab>
                <Tab>Needs</Tab>
              </TabList>

              <TabPanels>
                {/* Info */}
                <TabPanel>
                  <VStack
                    alignItems={"start"}
                    justifyContent={"start"}
                    spacing={"15px"}
                  >
                    <Box>

                      <HStack w="full" spacing={"5"}>
                        <InputGroup w="full" my="5">
                          <InputLeftAddon children="Details:" />
                          <Textarea type="text" value={textDetails} onChange={(e) => setTextDetails(e.target.value)} placeholder="text details" />
                        </InputGroup>
                        <InputGroup w="full" my="5">
                          <InputLeftAddon children="Location Url:" />
                          <Input type="text" value={locationUrl} onChange={(e) => setLocationUrl(e.target.value)} placeholder="location url" />
                        </InputGroup>
                      </HStack>
                      <FormControl my={"5"}>
                        <FormLabel> Building Image </FormLabel>
                        <Input type="file" w="full" pt={1} onChange={(e) => setBuildingPhoto(e.target.files[0])} placeholder="building Photo" />
                      <FormHelperText>{StayDetails?.location?.buildingPhoto?.length > 0 ? StayDetails?.location?.buildingPhoto.slice(0,50) : " Upload Building Photo " }</FormHelperText>
                      </FormControl>
                    </Box>
                    <Box>
                      <InputGroup w="full" my="">
                        <InputLeftAddon children="Keys:" />
                        <Textarea type="text" value={instructions} onChange={(e) => setInstructions(e.target.value)} placeholder="keys" />
                      </InputGroup>
                      <FormControl my={"5"}>
                        <FormLabel> Video </FormLabel>
                        <Input type="file" pt={1} onChange={(e) => setVideoUrl(e.target.files[0])} placeholder="videoUrl" />
                      <FormHelperText>{StayDetails?.keys?.videoUrl ? <Link w="full" ml={"10"} textColor="blue" href={videoUrl} target="_blank"> Video Link </Link> : "Upload Video" }</FormHelperText>
                      </FormControl>
                      <FormControl my={"5"}>
                        <FormLabel> Photo </FormLabel>
                        <Input type="file" pt="1" onChange={(e) => setPhotoUrl(e.target.files[0])} placeholder="photoUrl" />
                      <FormHelperText>{StayDetails?.keys?.photoUrl ? StayDetails?.keys?.photoUrl.slice(0,50)  : "Upload Photo" }</FormHelperText>
                      </FormControl>
                    </Box>
                  </VStack>
                </TabPanel>
                {/* Wifi - */}
                <TabPanel>
                  <VStack
                    alignItems={"start"}
                    justifyContent={"start"}
                    spacing={"15px"}
                  >

                    <HStack w="full" my={5} spacing={"5"}>
                      <InputGroup w="full" my="5">
                        <InputLeftAddon children="Wifi Name:" />
                        <Input type="text" value={wifiName} onChange={(e) => setWifiName(e.target.value)} placeholder="Wifi Name:" />
                      </InputGroup>
                      <InputGroup w="full" my="5">
                        <InputLeftAddon children="Wifi Password:" />
                        <Input type="text" value={wifiPassword} onChange={(e) => setWifiPassword(e.target.value)} placeholder="Wifi Password:" />
                      </InputGroup>
                    </HStack>
                    <HStack w="full" my={5} spacing={"5"}>
                      <InputGroup w="full" my="5">
                        <InputLeftAddon children="Parking:" />
                        <Textarea type="text" value={parkingDetails} onChange={(e) => setParkingDetails(e.target.value)} placeholder="Parking:" />
                      </InputGroup>
                      <FormControl my={"5"}>
                        <FormLabel> Parking Image </FormLabel>
                        <Input type="file" w="full" pt={1} onChange={(e) => setParkingImage(e.target.files[0])} placeholder="Parking Image" />
                      <FormHelperText>{StayDetails?.parking?.parkingImage ? StayDetails?.parking?.parkingImage.slice(0,50)  : "Upload Photo" }</FormHelperText>
                      </FormControl>
                    </HStack>
                    <HStack w="full" my={5} spacing={"5"}>
                      <InputGroup w="full" my="5">
                        <InputLeftAddon children="Electricity:" />
                        <Textarea type="text" value={electricityDetails} onChange={(e) => setElectricityDetails(e.target.value)} placeholder="Electricity:" />
                      </InputGroup>
                      <FormControl my={"5"}>
                        <FormLabel> Electric Image </FormLabel>
                        <Input type="file" w="full" pt={1} onChange={(e) => setElecImage(e.target.files[0])} placeholder="building Photo" />
                      <FormHelperText>{StayDetails?.electricity?.elecImage ? StayDetails.electricity.elecImage.slice(0,50)  : "Upload Photo" }</FormHelperText>
                      </FormControl>
                    </HStack>
                    <InputGroup w="25%" my="5">
                      <InputLeftAddon children="Garbage:" />
                      <Textarea type="text" value={garbage} onChange={(e) => setGarbage(e.target.value)} placeholder="Garbage:" />
                    </InputGroup>
                  </VStack>
                </TabPanel>
                {/* Emergency Info */}
                <TabPanel>
                  <VStack
                    alignItems={"start"}
                    justifyContent={"start"}
                    spacing={"20px"}
                  >
                    {/* Emergency Info  */}
                    <InputGroup w="50%" my="5">
                      <InputLeftAddon children="Emergency Info:" />
                      <Textarea type="text" value={emergencyInfo} onChange={(e) => setEmergencyInfo(e.target.value)} placeholder="Emergency Info:" />
                    </InputGroup>
                    {/* House Rules */}
                    <InputGroup w="50%" my="5">
                      <InputLeftAddon children="House Rules:" />
                      <Textarea type="text" value={houseRules} onChange={(e) => setHouseRules(e.target.value)} placeholder="House Rules:" />
                    </InputGroup>
                    {/* General Information */}
                    <InputGroup w="50%" my="5">
                      <InputLeftAddon children="General Information :" />
                      <Textarea type="text" value={generalInfo} onChange={(e) => setGeneralInfo(e.target.value)} placeholder="General Information :" />
                    </InputGroup>

                    {/* checkOut Instructions */}
                    <InputGroup w="50%" my="5">
                      <InputLeftAddon children="CheckOut Instructions :" />
                      <Textarea type="text" value={checkOutInstructions} onChange={(e) => setCheckOutInstructions(e.target.value)} placeholder="CheckOut Instructions :" />
                    </InputGroup>

                  </VStack>
                </TabPanel>
                {/* Local Conveniences */}
                <TabPanel>
                  <VStack
                    alignItems={"start"}
                    justifyContent={"start"}
                    spacing={"15px"}
                  >
                    {/* Grocery Stores  */}
                    <Text>Grocery Stores:</Text>
                    <Box>
                      {[...Array(groceryStores)].map((_, i) => (
                        <Box key={`groceryStores${i}`}>
                          <HStack my={5} w={""} alignItems={"center"}>
                            
                              <InputGroup>
                                <InputLeftAddon children="Text:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.grocertStores && StayDetails?.grocertStores[i]?.groceryDetails}
                                  id={`groceryText${i}`}
                                  placeholder="Grocery Name"
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputLeftAddon children="Phone:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.grocertStores && StayDetails?.grocertStores[i]?.phone}
                                  id={`groceryPhone${i}`}
                                  placeholder="Grocery Phone"
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputLeftAddon children="Link:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.grocertStores && StayDetails?.grocertStores[i]?.link}
                                  id={`groceryLink${i}`}
                                  placeholder="Grocery Link"
                                />
                              </InputGroup>
                            <Flex>
                              <Button
                                mr={2}
                                colorScheme={"green"}
                                onClick={() =>
                                  setGroceryStores((prev) => prev + 1)
                                }
                              >
                                <Icon as={BsPlusCircle} />
                              </Button>
                              <Button
                                onClick={() =>
                                  setGroceryStores(groceryStores - 1)
                                }
                                display={groceryStores === 1 ? "none" : ""}
                                colorScheme={"red"}
                              >
                                <Icon as={AiOutlineDelete} />
                              </Button>
                            </Flex>
                          </HStack>
                          
                        </Box>
                      ))}
                    </Box>
                    
                    {/* Restaurants */}
                    <Text>Restaurants:</Text>
                    <Box>
                      {[...Array(restaurants)].map((_, i) => (
                        <Box key={`restaurants${i}`}>
                          <HStack my={5} w={""} alignItems={"center"}>
                            
                              <InputGroup>
                                <InputLeftAddon children="Text:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.resturants && StayDetails?.resturants[i]?.resturantDetails}
                                  id={`restaurantsText${i}`}
                                  placeholder="Restaurants Name"
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputLeftAddon children="Phone:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.resturants && StayDetails?.resturants[i]?.phone}
                                  id={`restaurantsPhone${i}`}
                                  placeholder="Restaurants Phone"
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputLeftAddon children="Link:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.resturants && StayDetails?.resturants[i]?.link}
                                  id={`restaurantsLink${i}`}
                                  placeholder="Restaurants Link"
                                />
                              </InputGroup>
                            <Flex>
                              <Button
                                mr={2}
                                colorScheme={"green"}
                                onClick={() =>
                                  setRestaurants((prev) => prev + 1)
                                }
                              >
                                <Icon as={BsPlusCircle} />
                              </Button>
                              <Button
                                onClick={() =>
                                  setRestaurants(restaurants - 1)
                                }
                                display={restaurants === 1 ? "none" : ""}
                                colorScheme={"red"}
                              >
                                <Icon as={AiOutlineDelete} />
                              </Button>
                            </Flex>
                          </HStack>
                          
                        </Box>
                      ))}
                    </Box>
                    {/* Nightlife Spots */}
                    <Text>Nightlife Spots:</Text>
                    <Box>
                      {[...Array(nightlife)].map((_, i) => (
                        <Box key={`nightlife${i}`}>
                          <HStack my={5} w={""} alignItems={"center"}>
                            
                              <InputGroup>
                                <InputLeftAddon children="Text:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.nightSpots && StayDetails?.nightSpots[i]?.nightSpotDetails}
                                  id={`nightlifeText${i}`}
                                  placeholder="Nightlife Name"
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputLeftAddon children="Phone:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.nightSpots && StayDetails?.nightSpots[i]?.phone}
                                  id={`nightlifePhone${i}`}
                                  placeholder="Nightlife Phone"
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputLeftAddon children="Link:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.nightSpots && StayDetails?.nightSpots[i]?.link}
                                  id={`nightlifeLink${i}`}
                                  placeholder="Nightlife Link"
                                />
                              </InputGroup>
                            <Flex>
                              <Button
                                mr={2}
                                colorScheme={"green"}
                                onClick={() =>
                                  setNightlife((prev) => prev + 1)
                                }
                              >
                                <Icon as={BsPlusCircle} />
                              </Button>
                              <Button
                                onClick={() =>
                                  setNightlife(nightlife - 1)
                                }
                                display={nightlife === 1 ? "none" : ""}
                                colorScheme={"red"}
                              >
                                <Icon as={AiOutlineDelete} />
                              </Button>
                            </Flex>
                          </HStack>
                          
                        </Box>
                      ))}
                    </Box>
                    <Text>Entertainment:</Text>
                    <Box>
                      {[...Array(entertainment)].map((_, i) => (
                        <Box key={`entertainment${i}`}>
                          <HStack my={5} w={""} alignItems={"center"}>
                            
                              <InputGroup>
                                <InputLeftAddon children="Text:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.entertainment && StayDetails?.entertainment[i]?.entertainmentDetails}
                                  id={`entertainmentText${i}`}
                                  placeholder="Entertainment Name"
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputLeftAddon children="Phone:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.entertainment && StayDetails?.entertainment[i]?.phone}
                                  id={`entertainmentPhone${i}`}
                                  placeholder="Entertainment Phone"
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputLeftAddon children="Link:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.entertainment && StayDetails?.entertainment[i]?.link}
                                  id={`entertainmentLink${i}`}
                                  placeholder="Entertainment Link"
                                />
                              </InputGroup>
                            <Flex>
                              <Button
                                mr={2}
                                colorScheme={"green"}
                                onClick={() =>
                                  setEntertainment((prev) => prev + 1)
                                }
                              >
                                <Icon as={BsPlusCircle} />
                              </Button>
                              <Button
                                onClick={() =>
                                  setEntertainment(entertainment - 1)
                                }
                                display={entertainment === 1 ? "none" : ""}
                                colorScheme={"red"}
                              >
                                <Icon as={AiOutlineDelete} />
                              </Button>
                            </Flex>
                          </HStack>
                          
                        </Box>
                      ))}
                    </Box>
                    {/* Transportation */}
                    <Text>Means of Public Transportation:</Text>
                    <Box>
                      {[...Array(transportation)].map((_, i) => (
                        <Box key={`transportation${i}`}>
                          <HStack my={5} w={""} alignItems={"center"}>
                            
                              <InputGroup>
                                <InputLeftAddon children="Text:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.transportation && StayDetails?.transportation[i]?.transportationDetails}
                                  id={`transportationText${i}`}
                                  placeholder="Transportation Name"
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputLeftAddon children="Phone:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.transportation && StayDetails?.transportation[i]?.phone}
                                  id={`transportationPhone${i}`}
                                  placeholder="Transportation Phone"
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputLeftAddon children="Link:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={StayDetails?.transportation && StayDetails?.transportation[i]?.link}
                                  id={`transportationLink${i}`}
                                  placeholder="Transportation Link"
                                />
                              </InputGroup>
                            <Flex>
                              <Button
                                mr={2}
                                colorScheme={"green"}
                                onClick={() =>
                                  setTransportation((prev) => prev + 1)
                                }
                              >
                                <Icon as={BsPlusCircle} />
                              </Button>
                              <Button
                                onClick={() =>
                                  setTransportation(transportation - 1)
                                }
                                display={transportation === 1 ? "none" : ""}
                                colorScheme={"red"}
                              >
                                <Icon as={AiOutlineDelete} />
                              </Button>
                            </Flex>
                          </HStack>
                          
                        </Box>
                      ))}
                    </Box>
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => handleClose()}>
              Close
            </Button>

            <Button variant="outline" onClick={() => handlePost()} bg={"green"} textColor={"white"}>Add <Icon ml={2} as={PlusSquareIcon} /></Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddStayDetails;
