import { Icon } from "@chakra-ui/icons";
import Cookies from 'js-cookie'
import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
 
  useDisclosure,
  useToast
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { BsImage } from "react-icons/bs";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import "../Test/App.css";
import { FiTrash } from "react-icons/fi";
import * as api from '../API/mediaApi';
import { useMutation, useQueryClient } from "react-query";

function AddImages({ type, Id, oldImages }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const [oldies,setOldies] = useState(oldImages)
  const toast = useToast()

   // Add Images
   const { mutate:postMutate } = useMutation([],api.addImage, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["Products"]) 
          queryClient.invalidateQueries(["Stays"])
          toast({
            title: "Added successfully",
            status: 'success',
            duration: 9000,
            isClosable: true,
          }) 
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })

   // resort Images
   const { mutate:resortMutate } = useMutation([],api.resortImage, {
    onSuccess:
      () => {
        queryClient.invalidateQueries(["Products"]) 
          queryClient.invalidateQueries(["Stays"])
          toast({
            title: "Added successfully",
            status: 'success',
            duration: 9000,
            isClosable: true,
          }) 
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  })


   // Delete
  const { mutate:deleteMutate } = useMutation([],api.deleteStay, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["Products"]) 
          queryClient.invalidateQueries(["Stays"])
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  });

  const [images, setImages] = useState([]);
  const [imagesUrl, setImagesUrl] = useState([]);
  
  const onSortOldies = (oldIndex, newIndex) => {
    setOldies((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };
  const onSortEnd = (oldIndex, newIndex) => {
    setImages((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };
  function onChange(event) {
    setImages([...images, ...event.target.files]);
  }
  useEffect(() => {
    const newImagesUrls = [];
    images?.forEach((image) => newImagesUrls.push(URL.createObjectURL(image)));
    setImagesUrl(newImagesUrls);
  }, [images]);
  
  async function onSubmit() {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("typeId", Id);
    images.map((file) => {
      formData.append("images", file);
    });
    postMutate(formData);
  }

  async function deleteImage(imgToDelete) {
    const data = { 
      img:imgToDelete,
      type,
      typeId:Id,
    }
    deleteMutate(data)
    
  }

  function resort(){
    const data = { 
      type,
      typeId:Id,
      images:oldies
    }
    resortMutate(data)
  }

  let img1;
  function handleDelete(index) {
    img1 = images;
    img1.splice(index, 1);
    setImages([...img1]);
  }

  return (
    <>
      <Icon cursor={"pointer"} as={BsImage} onClick={onOpen} />

      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Images</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box ml="10px" mt="20px">
              <Input
                type="file"
                multiple
                accept="image/*"
                pt={1}
                onChange={(e) => onChange(e)}
              />

              <SortableList
                onSortEnd={onSortOldies}
                draggedItemClassName="draggedItem"
                className="draggedItem"
              >
                {oldies?.map((img, i) => (
                  <SortableItem key={img+i}>
                    <Flex mb={"5"} w="20%" mr={"30px"} mt={"10"}>
                      <Image  cursor={"grab"}
                        draggable={false} src={img} width={"150px"} height={"150px"}/>
                        <Icon cursor={"pointer"} as={FiTrash}  w={10} h={10} onClick={() =>deleteImage(img)}/>
                    </Flex>
                  </SortableItem>
                ))}
                </SortableList>
                <Divider orientation='horizontal' />
                <SortableList 
                onSortEnd={onSortEnd}
                draggedItemClassName="draggedItem"
                className="draggedItem">
                {imagesUrl?.map((image, i) => (
                  <SortableItem key={i}>
                    <Box w="20%" mr={"30px"} mt={"10"}>
                      <Icon
                        w={10}
                        h={10}
                        cursor={"pointer"}
                        onClick={() => handleDelete(i)}
                        as={FiTrash}
                      />
                      <Image
                        cursor={"grab"}
                        draggable={false}
                        src={image}
                        width={"150px"}
                        height={"150px"}
                        alt=""
                      />
                    </Box>
                  </SortableItem>
                ))}
              </SortableList>
            </Box>
          </ModalBody>
          
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button onClick={onSubmit} colorScheme="green">
              Upload
            </Button>
            <Button ml={5} onClick={() => resort()} colorScheme="purple">
              Resort
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddImages;
