import { Icon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  toast,
  useDisclosure,
} from "@chakra-ui/react";

import * as api from "../API/mediaApi";
import { useEffect, useState } from "react";
import { BsImage } from "react-icons/bs";
import axios from "axios";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import "../Test/App.css";
import Cookies from "js-cookie";
import { FiTrash } from "react-icons/fi";

import { useMutation, useQueryClient } from "react-query";
import { baseURL } from "../config";
function AddTourImages({ type, Id, oldImages }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [oldies, setOldies] = useState(oldImages);
  const queryClient = useQueryClient();
  const [images, setImages] = useState([]);
  const adminToken = Cookies.get("admin-token");
  const [imagesUrl, setImagesUrl] = useState([]);


  const { mutate:deleteMutate } = useMutation([],api.deleteStay, {
    onSuccess:
      () => {
          queryClient.invalidateQueries(["Tours"]);
          toast({
            title: 'Deleted Successfully',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
    },
    onError:(error) => {
      toast({
        title: error.response.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
})

  const onSortOldies = (oldIndex, newIndex) => {
    setOldies((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  const onSortEnd = (oldIndex, newIndex) => {
    setImages((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };
  function onChange(event) {
    setImages([...images, ...event.target.files]);
  }

  // resort Images
  const { mutate: resortMutate } = useMutation([], api.resortImage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Tours"]);
      toast({
        title: "Added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    const newImagesUrls = [];
    images?.forEach((image) => newImagesUrls.push(URL.createObjectURL(image)));
    setImagesUrl(newImagesUrls);
  }, [images]);

  async function onSubmit() {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("typeId", Id);
    images.map((file) => {
      formData.append("images", file);
    });
    axios
      .post(`${baseURL}/v1/media?type=${type}&typeId=` + Id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: adminToken,
        },
      })
      .then((data) => {
        queryClient.invalidateQueries(["Tours"]);
        toast({
          title: "Added successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((error) => {
        toast({
          title: error.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  }

  function resort() {
    const data = {
      type,
      typeId: Id,
      images: oldies,
    };
    resortMutate(data);
  }

  async function onDelete(imagetoDelete) {
    const body = {
      type,
      typeId: Id,
      img: imagetoDelete,
    };
      deleteMutate(body)
  }

  let img1;
  function handleDelete(index) {
    img1 = images;
    img1.splice(index, 1);
    setImages([...img1]);
  }

  return (
    <>
      <Icon cursor={"pointer"} as={BsImage} onClick={onOpen} />

      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Images</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box ml="10px" mt="20px">
              <Input
                type="file"
                multiple
                accept="image/*"
                pt={1}
                onChange={(e) => onChange(e)}
              />

              <SortableList
                onSortEnd={onSortOldies}
                draggedItemClassName="draggedItem"
                className="draggedItem"
              >
                {oldies?.map((img, i) => (
                  <SortableItem key={img + i}>
                    <Flex mb={"5"} w="20%" mr={"30px"} mt={"10"}>
                      <Image
                        cursor={"grab"}
                        draggable={false}
                        src={img}
                        width={"150px"}
                        height={"150px"}
                      />
                      <Icon
                        cursor={"pointer"}
                        as={FiTrash}
                        w={10}
                        h={10}
                        onClick={() => onDelete(img)}
                      />
                    </Flex>
                  </SortableItem>
                ))}
              </SortableList>
              <Divider orientation="horizontal" />
              <SortableList
                onSortEnd={onSortEnd}
                draggedItemClassName="draggedItem"
                className="draggedItem"
              >
                {imagesUrl?.map((image, i) => (
                  <SortableItem key={i}>
                    <Box w="20%" mr={"30px"} mt={"10"}>
                      <Icon
                        w={10}
                        h={10}
                        cursor={"pointer"}
                        onClick={() => handleDelete(i)}
                        as={FiTrash}
                      />
                      <Image
                        cursor={"grab"}
                        draggable={false}
                        src={image}
                        width={"150px"}
                        height={"150px"}
                        alt=""
                      />
                    </Box>
                  </SortableItem>
                ))}
              </SortableList>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button onClick={onSubmit} colorScheme="green">
              Upload
            </Button>
            <Button ml={5} onClick={() => resort()} colorScheme="purple">
              Resort
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddTourImages;
