import {
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Link,
    Stack,
    Image,
    useToast
  } from '@chakra-ui/react';
  import Cookies from "js-cookie";
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
  import * as api from '../components/API/adminApi'
  export default function Login() {
    
    const history = useHistory()
  const user = Cookies.get('admin-token')
 
  const toast = useToast()
  const fcmToken = Cookies.get('fcm-admin-token')
  
  useEffect(() => {
      if(user){
        history.push('/dashboard')
      }
      if(localStorage?.getItem('email')) setEmail(JSON.parse(localStorage?.getItem('email')))
      if(localStorage?.getItem('password')) setPassword(JSON.parse(localStorage?.getItem('password')))
  },[])
    const [email,setEmail] = useState()
    const [password,setPassword] = useState()
    const [me,setMe] = useState(false)
    const { mutate  } = useMutation([], api.loginAdmin, {
      onSuccess: (data) => {
        Cookies.set('admin-token',data.token)
        Cookies.set('admin-role',data.role)
        toast({
          title: 'Success.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        setTimeout(() => {
          window.location.href = '/dashboard'
        }, 1500);
        
      },
      onError: (error) => {
          
          toast({
            title: '404 Error.',
            description:error.response.data.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
      }
    });

    function handleSubmit() {
      if(me){
        localStorage.setItem('email',JSON.stringify(email))
        localStorage.setItem('password',JSON.stringify(password))
      }
      const body ={
        email,
        password,
        fcmToken
      }
      mutate(body)
    }


    return (
      <Stack minH={'100vh'} overflow={"-moz-hidden-unscrollable"} direction={{ base: 'column', md: 'row' }}>
        <Flex p={8} flex={1} align={'center'} justify={'center'}>
          <Stack spacing={4} w={'full'} maxW={'md'}>
            <Heading fontSize={'2xl'}>Sign in to your account</Heading>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input  type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" value={password} onChange ={ (e) => setPassword(e.target.value)} />
            </FormControl>
            <Stack spacing={6}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
                <Checkbox value={me} onChange={(e) =>setMe(e.target.checked)}>Remember me</Checkbox>
                <Link color={'blue.500'}>Forgot password?</Link>
              </Stack>
              <Button onClick={() => handleSubmit()} bg={"#FED438"} variant={'solid'}>
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Flex>
        <Flex flex={1}>
          <Image
            alt={'Login Image'}
            objectFit={'cover'}
            src={
              'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80'
            }
          />
        </Flex>
      </Stack>
    );
  }