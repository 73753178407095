
import { useEffect, useState } from 'react'
import { db } from '../firebase';
import firebase from 'firebase'
import { Box, Button, Flex, Image, Input, LinkBox, Text, VStack  } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';





function Messages() {
  const [testUser, setTestUser] = useState(firebase.auth().currentUser)
  const userId = "6197661dfe5ed9cff66b5772"
  const [message, setMessage] = useState()



  const [posts, setPosts] = useState();

  useEffect(() => {

    if (testUser === null) {
      firebase.auth().signInAnonymously()
        .then((data) => {
          setTestUser(data)
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          // ...
        });
    }

    return db.collection('Chats').orderBy('lastUpdated','desc').onSnapshot((snapshot) => {
      const postData = [];
      snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
      console.log(postData);  // <------
      setPosts(postData);
    })

  }, [testUser]);
  

  return (
    <Box mt={"7%"} mx={"5%"} w="full">
      <Box spacing={"10"} >
        {posts?.map(post => {
          return(
          <Box my="5">
          <Link   to={`message/${post._id}`}>
            <Flex justifyContent={"space-between"} py={5} px={4} border="1px" rounded={"lg"}>
              <Text>{post.lastMessage}</Text>
              <Flex>
                <Box mr={"2"}>
                <Text>{post?.username}</Text>
              <Text>{dayjs(post.lastUpdated?.seconds * 1000).format('DD/MM HH:mm')}</Text>
              </Box>
              <Image src={post?.photoUrl} w="10" h="10" rounded="full" />
              </Flex>
            </Flex>
            <Box>
            </Box>
          </Link>
          </Box>
        )})}

      </Box>
    </Box>
  )
}

export default Messages 
